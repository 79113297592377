import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../../components/layout'
import BlogPosts from '../../../components/BlogPosts'
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../../../utils/htmlSerializer'
import goBackArrow from '../../../assets/baby-pack/go_back.svg'

// Query for the Blog Home content in Prismic
export const query = graphql`
  {
    prismic {
      allBlog_homepages(uid: null) {
        edges {
          node {
            blog_title
            blog_richtext
            _linkType
            _meta {
              id
              type
            }
          }
        }
      }
      allBlog_postss {
        edges {
          node {
            blog_title
            blog_date
            blog_header_image
            blog_description
            blog_section
            _linkType
            _meta {
              id
              type
              uid
            }
            body {
              ... on PRISMIC_Blog_postsBodyCustom {
                type
                label
                primary {
                  custom_rich_text
                  custom_highlight
                  custom_image
                  custom_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// Using the queried Blog Home document data, we render the top section
const BlogHomeHead = ({ home }) => {
  return (
    <div className="home-header container">
      <h2 className="text-4xl mt-4">Astuces</h2>
      <Link to="/blog/" className="flex align-middle">
        <span className="text-2xl text-gray-700 ml-4">Revenir au blog</span>
      </Link>
    </div>
  )
}

const blog = ({ data }) => {
  // Define the Blog Home & Blog Post content returned from Prismic
  const home = data.prismic.allBlog_homepages.edges[0].node
  const posts = data.prismic.allBlog_postss.edges.filter((doc) => {
    const fromPrismic = doc.node.blog_section[0].text
    const cleanedArray = fromPrismic.split(',').map((e) => e.toLowerCase().trim())
    return cleanedArray.includes('astuces')
  })

  // if (!doc) return null

  return (
    <Layout>
      <BlogHomeHead home={home} />
      <BlogPosts posts={posts} />
    </Layout>
  )
}
export default blog
