import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { RichText, Date } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'

// Function to retrieve a small preview of the post's text
const firstParagraph = (post) => {
  // Find the first text slice of post's body
  let firstTextSlice = post.blog_description[0].text
  if (firstTextSlice != null) {
    // Set the character limit for the text we'll show in the homepage
    const textLimit = 300
    let text = firstTextSlice
    let limitedText = text.substring(0, textLimit)

    if (text.length > textLimit) {
      // Cut only up to the last word and attach '...' for readability
      return <p>{limitedText.substring(0, limitedText.lastIndexOf(' ')) + '...'}</p>
    } else {
      // If it's shorter than the limit, just show it normally
      return <p>{text}</p>
    }
  } else {
    // If there are no slices of type 'text', return nothing
    return null
  }
}

// A summary of the Blog Post
const PostSummary = ({ post }) => {
  // Store and format the blog post's publication date
  let postDate = Date(post.blog_date)
  postDate = postDate
    ? new Intl.DateTimeFormat('fr-FR', {
      /* eslint-disable prettier/prettier */
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    }).format(postDate)
    : ''
  /* eslint-enable prettier/prettier */

  // Default title when post has no title set
  const defaultTitle = 'Untitled'

  return (
    <div className="post-summary mt-16 md:mt-8 mx-auto text-center max-w-3xl mx-8" key={post.id}>
      <h2 className="font-header text-4xl md:text-3xl text-custom-purple font-bold md:max-w-lg mx-auto">
        {/* We render a link to a particular post using the linkResolver for the url and its title */}
        {RichText.asText(post.blog_title).length !== 0 ? RichText.asText(post.blog_title) : defaultTitle}
      </h2>
      <p className="blog-post-meta text-gray-600 text-xl">
        Rédigé le <time>{postDate}</time>
      </p>
      <img
        className="rounded-xl mx-auto my-4"
        src={post.blog_header_image.url}
        alt={post.blog_header_image.alt}
        title={post.blog_header_image.copyright}
      />
      {/* Renders a small preview of the post's text */}
      <div className="text-2xl md:text-xl text-gray-700 md:max-w-lg mx-auto">{firstParagraph(post)}</div>

      <AniLink paintDrip color="rebeccapurple" duration={0.7} to={linkResolver(post)}>
        <span className="read-more text-lilas4 text-3xl md:text-sm font-bold">Lire l&apos;article</span>
      </AniLink>
    </div>
  )
}

export default ({ posts }) => {
  if (!posts) return null

  return (
    <div className="blog-posts container mx-auto">
      {posts.map((post) => {
        return <PostSummary post={post.node} key={post.node._meta.id} />
      })}
    </div>
  )
}
